<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/manPower/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">HUMAN</h1>
        </div>
        <div class="title-middle">
          <h1 class="title-h1">RESOURCES</h1>
        </div>
        <div class="title-bottom">
          人力资源
        </div>
      </div>
      <div class="text">
        <div class="text-title">加入我们，一起创造无限可能</div>
        <div class="text-EN">JOIN US AND CREATE INFINITE</div>
        <div class="text-EN">POSSIBILITIES TOGETHER</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Power'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 22% 15% 15%;
    background: url("../assets/manPower/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 15%;
      box-sizing: border-box;
      .img-icon {
        height: 65px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text-title {
        font-size: 50px;
        padding-bottom: 2%;
      }
      .text-EN {
        font-size: 36px;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .container {
    .content {
      padding: 22% 15% 30%;
      box-sizing: border-box;
      .title {
        // padding-bottom: %;
        .title-h1 {
          font-size: 47px;
        }

        .title-bottom {
          font-size: 31px;
        }
        .img-icon {
          width: 50px;
          height: 30px;
        }
      }
      .text {
        .text-title {
          font-size: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 5% 50%;
      box-sizing: border-box;
      .title {
        // padding-bottom: %;
        .title-h1 {
          font-size: 30px;
        }

        .title-bottom {
          font-size: 28px;
        }
        .img-icon {
          width: 30px;
          height: 30px;
        }
      }
      .text {
        .text-title {
          font-size: 25px;
        }
        .text-EN {
          font-size: 18px;
        }
      }
    }
  }
}
</style>